import React, { Component } from 'react';
import { NotFound } from './pages/NotFound/NotFound';
import { Switch, Route } from 'dva/router';
import { connect } from 'dva';
import { dynamicLoad, PageComponent } from 'jad-lib-react';

// 根据后端返回的信息, 渲染route
// key和返回的id一一对应, 其key是加载的路径
function genConfig(app) {
    return {
        home: {
            path: '/home',
            component: dynamicLoad(app, import('./pages/Home/Home'), []),
        },
        static: {
            // http://tuiluck.cn/lteFSSLnzx.txt
            // http://a.gmslucky.cn/lteFSSLnzx.txt
            // 诺禾媒体投放需要的验证文件
            path: '/lteFSSLnzx.txt',
            component: dynamicLoad(app, import('./pages/Static/StaticlteFSSLnzxTxt'), []),
        },
        static_1KYKqVUq5V: {
            path: '/1KYKqVUq5V.txt',
            component: dynamicLoad(app, import('./pages/Static/Static_1KYKqVUq5V'), []),
        },
        static_3YGQQsl7Tn: {
            path: '/3YGQQsl7Tn.txt',
            component: dynamicLoad(app, import('./pages/Static/Static_3YGQQsl7Tn'), []),
        },
        static_BNPx01u6pS: {
            path: '/BNPx01u6pS.txt',
            component: dynamicLoad(app, import('./pages/Static/StaticBNPx01u6pS'), []),
        },
        static_BVXtnGyVaP: {
            path: '/BVXtnGyVaP.txt',
            component: dynamicLoad(app, import('./pages/Static/Static_BVXtnGyVaP'), []),
        },
        static_NDtFpyEBQf: {
            path: '/NDtFpyEBQf.txt',
            component: dynamicLoad(app, import('./pages/Static/Static_NDtFpyEBQf'), []),
        },
        static_dBBWG4HbhO: {
            path: '/dBBWG4HbhO.txt',
            component: dynamicLoad(app, import('./pages/Static/Static_dBBWG4HbhO'), []),
        },
        static_hDhcSzdC6a: {
            path: '/hDhcSzdC6a.txt',
            component: dynamicLoad(app, import('./pages/Static/Static_hDhcSzdC6a'), []),
        },
        static_o3ENhOFXSN: {
            path: '/o3ENhOFXSN.txt',
            component: dynamicLoad(app, import('./pages/Static/Static_o3ENhOFXSN'), []),
        },

        realization: {
            path: '/realization',
            component: dynamicLoad(app, import('./pages/Realization/Realization'), []),
        },
        programmatic: {
            path: '/',
            component: dynamicLoad(app, import('./pages/Programmatic/Programmatic'), []),
        },
        product: {
            path: '/product',
            component: dynamicLoad(app, import('./pages/Product/Product'), []),
        },
        media: {
            path: '/media',
            component: dynamicLoad(app, import('./pages/Media/Media'), []),
        },
        about: {
            path: '/about',
            component: dynamicLoad(app, import('./pages/About/About'), []),
        },
        privacy: {
            path: '/privacy',
            component: dynamicLoad(app, import('./pages/About/Privacy'), []),
        },
        contactUs: {
            path: '/contact-us',
            component: dynamicLoad(app, import('./pages/ConcatUs/ConcatUs'), []),
        },
        team: {
            path: '/team',
            component: dynamicLoad(app, import('./pages/Team/Team'), []),
        },
        applyFor: {
            path: '/apply-for',
            component: dynamicLoad(app, import('./pages/ApplyFor/ApplyFor'), []),
        },
        login: {
            path: '/login',
            component: dynamicLoad(app, import('./pages/Login/Login'), []),
        },
        registor: {
            path: '/registor',
            component: dynamicLoad(app, import('./pages/Registor/Registor'), []),
        },
        Activity: {
            path: '/landing/ydhk-v1',
            component: dynamicLoad(app, import('./pages/Activity/Activity'), []),
        },
        ActivityTwo: {
            path: '/landing/ydhk-v2',
            component: dynamicLoad(app, import('./pages/Activity/Activity2'), []),
        },
        ActivityThree: {
            path: '/landing/bfl-v1',
            component: dynamicLoad(app, import('./pages/Activity/Activity3'), []),
        },
        ActivityFour: {
            path: '/landing/ydhk-v3',
            component: dynamicLoad(app, import('./pages/Activity/Activity4'), []),
        },
        TestRedirectTo: {
            path: '/landing/middleway',
            component: dynamicLoad(app, import('./pages/Activity/RedirectTo'), []),
        },
        forget: {
            path: '/forget',
            component: dynamicLoad(app, import('./pages/Forget/Forget'), []),
        },
        Caimi: {
            path: '/caimi',
            component: dynamicLoad(app, import('./pages/Caimi/index'), []),
        },
        TTCDup: {
            path: '/TTCD/ttcd_uprivacy',
            component: dynamicLoad(app, import('./pages/TTCD/ttcd_uprivacy'), []),
        },
        TTCDpr: {
            path: '/TTCD/ttcd_privacy',
            component: dynamicLoad(app, import('./pages/TTCD/ttcd_privacy'), []),
        },
    };
}
@connect(({ sidebar }) => ({ sidebar }))
export class RouteConfig extends Component<
    PageComponent<{
        sidebar?: any;
        newWebsite?: any;
    }>
> {
    state = {
        routeIdsAllowed: [],
        config: genConfig(window['__DVA_INSTANCE']),
    };
    render() {
        const { config } = this.state;
        const routeAllow = Object.keys(config)
            .map(key => {
                let item = config[key];
                console.log("debug:", key, item);
                if (!config[key]) return;
                return (
                    <Route
                        key={item.path}
                        exact={true}
                        path={item.path}
                        component={item.component}
                    />
                );
            })
            .filter(e => e);
        return (
            <Switch>
                {routeAllow}
                <Route exact path="/404" component={NotFound} />
                <Route component={NotFound} />
            </Switch>
        );
    }
}
